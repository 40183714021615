import React from "react";
import UpdatingSvg from "../images/updating.svg";

function CommingSoon() {

  return (
<div className="text-center mt-10">
    <p>Nous mettrons à jour cette page trés rapidement...</p>
    <img className="mt-10 mx-auto" src={UpdatingSvg} alt="mise à jour en cours"/>
  </div>
  );
}

export default CommingSoon;